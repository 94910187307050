import { createApp } from 'vue'
import App from './App.vue'
// Import Bootstrap CSS files
import Home from './components/Home.vue'
import Otp from './components/Otp.vue'
import Login from './components/Login.vue'
import AllCourse from './components/AllCourse.vue'
import MyCourse from './components/MyCourse.vue'
import {createRouter, createWebHistory} from 'vue-router'
const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home,
   
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
   
  },
  {
    path: '/Otp',
    name: 'Otp',
    component: Otp,
   
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
   
  },
  {
    path: '/AllCourse',
    name: 'AllCourse',
    component: AllCourse,
   
  },
  {
    path: '/MyCourse',
    name: 'MyCourse',
    component: MyCourse,
   
  },
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
createApp(App).use(router).mount('#app')