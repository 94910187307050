<template>
   <div class="container body">
      <div class="row">
         <div class="col-12" align="center">
            <img src="../assets/img/logo/logo.png" alt="logo" class="img-fluid">
            <p style="font-family: 'Courier New', monospace;">Login with mobile number</p>
            <hr>
            <div class="col-12">
               <input type="number" class=" input" placeholder="+91-9876543210">
            </div>
            <div class="col-12 mt-5">
               <button type="submit" class="btn btn-grad">Send Code</button>
            </div>
         </div>
      </div>
   </div>
 </template>
 
 <script>
 export default {
   name: "LoginView",
 };
 </script>
 <style>
 
 .body{
   width: 33.33%;
   background-image: linear-gradient(to right, #666600 0%, #999966  51%, #666600  100%);
   color: #fff;
 }
 .input{
   border: none;
   width: 100%;
   height: 50px;
   line-height: 50px;
   border-bottom: 1px solid;
   outline:none;
   background: transparent;
   color: #fff;
 }
 *:focus {
    outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::placeholder {
  color: #fff;
  opacity: 0.7;
}
input[type=number] {
  -moz-appearance: textfield;
}

.btn-grad {background-image: linear-gradient(to right, #EDE574 0%, #E1F5C4  51%, #EDE574  100%)}
         .btn-grad {
            margin: 10px;
            padding: 15px 45px;
            text-align: center;
            transition: 0.5s;
            height: 40px;
            line-height: 10px;
            background-size: 200% auto;
            /* color: white;             */
            /* box-shadow: 0 0 20px #eee; */
            border-radius: 10px;
            display: block;
          }

          .btn-grad:hover {
            background-position: right center; /* change the direction of the change here */
            /* color: #fff; */
            text-decoration: none;
          }
         
@media screen and (max-width:500px) {
   .body{
      width: 100%;
   }
}
</style>