<template>
   <div class="container-fluid">
      <div class="row top-nav">
         <div class="col-1">
            <i class="fa fa-arrow-left float-left"></i>
         </div>
         <div class="col-11" align="center">
            <p class="text-white">All Courses</p>
         </div>
      </div>
      <div class="row">
            <div class="course__box_header">
                <div class="col-12 pb-3">
                    <b>Courses (2)</b>
                    <i class="fa fa-filter float-right"></i>
                </div>    
            </div>
       </div>
    <!-- <div class="row"> -->
        <div class="course__box_all">
            <div class="row">
                <div class="col-4" align="center">
                    <img src="https://railsware.com/blog/wp-content/uploads/2019/04/Digital-Product-Development_Content-1024x639.jpg" alt="" class="product__image">            
                </div>
                <div class="col-8">
                    <span class="tags">Files</span>
                    <h6 >advance booking (offline) - february batch </h6>
                    <h6>&#8377;9999/-</h6>
                </div>
            </div>
        </div>
        <div class="course__box_all">
            <div class="row">
                <div class="col-4" align="center">
                    <img src="https://railsware.com/blog/wp-content/uploads/2019/04/Digital-Product-Development_Content-1024x639.jpg" alt="" class="product__image">            
                </div>
                <div class="col-8">
                    <span class="tags">Files</span>
                    <h6 >advance booking (offline) - february batch </h6>
                    <h6>&#8377;9999/-</h6>
                </div>
            </div>
        </div>
        <div class="course__box_all">
            <div class="row">
                <div class="col-4" align="center">
                    <img src="https://railsware.com/blog/wp-content/uploads/2019/04/Digital-Product-Development_Content-1024x639.jpg" alt="" class="product__image">            
                </div>
                <div class="col-8">
                    <span class="tags">Files</span>
                    <h6 >advance booking (offline) - february batch </h6>
                    <h6>&#8377;9999/-</h6>
                </div>
            </div>
        </div>
        <div class="course__box_all">
            <div class="row">
                <div class="col-4" align="center">
                    <img src="https://railsware.com/blog/wp-content/uploads/2019/04/Digital-Product-Development_Content-1024x639.jpg" alt="" class="product__image">            
                </div>
                <div class="col-8">
                    <span class="tags">Files</span>
                    <h6 >advance booking (offline) - february batch </h6>
                    <h6>&#8377;9999/-</h6>
                </div>
            </div>
        </div>
        <div class="course__box_all">
            <div class="row">
                <div class="col-4" align="center">
                    <img src="https://railsware.com/blog/wp-content/uploads/2019/04/Digital-Product-Development_Content-1024x639.jpg" alt="" class="product__image">            
                </div>
                <div class="col-8">
                    <span class="tags">Files</span>
                    <h6 >advance booking (offline) - february batch </h6>
                    <h6>&#8377;9999/-</h6>
                </div>
            </div>
        </div>
        <div class="course__box_all">
            <div class="row">
                <div class="col-4" align="center">
                    <img src="https://railsware.com/blog/wp-content/uploads/2019/04/Digital-Product-Development_Content-1024x639.jpg" alt="" class="product__image">            
                </div>
                <div class="col-8">
                    <span class="tags">Files</span>
                    <h6 >advance booking (offline) - february batch </h6>
                    <h6>&#8377;9999/-</h6>
                </div>
            </div>
        </div>
        <div class="course__box_all">
            <div class="row">
                <div class="col-4" align="center">
                    <img src="https://railsware.com/blog/wp-content/uploads/2019/04/Digital-Product-Development_Content-1024x639.jpg" alt="" class="product__image">            
                </div>
                <div class="col-8">
                    <span class="tags">Files</span>
                    <h6 >advance booking (offline) - february batch </h6>
                    <h6>&#8377;9999/-</h6>
                </div>
            </div>
        </div>
        <div class="course__box_all">
            <div class="row">
                <div class="col-4" align="center">
                    <img src="https://railsware.com/blog/wp-content/uploads/2019/04/Digital-Product-Development_Content-1024x639.jpg" alt="" class="product__image">            
                </div>
                <div class="col-8">
                    <span class="tags">Files</span>
                    <h6 >advance booking (offline) - february batch </h6>
                    <h6>&#8377;9999/-</h6>
                </div>
            </div>
        </div>
        <div class="course__box_all">
            <div class="row">
                <div class="col-4" align="center">
                    <img src="https://railsware.com/blog/wp-content/uploads/2019/04/Digital-Product-Development_Content-1024x639.jpg" alt="" class="product__image">            
                </div>
                <div class="col-8">
                    <span class="tags">Files</span>
                    <h6 >advance booking (offline) - february batch </h6>
                    <h6>&#8377;9999/-</h6>
                </div>
            </div>
        </div>
        <div class="course__box_all">
            <div class="row">
                <div class="col-4" align="center">
                    <img src="https://railsware.com/blog/wp-content/uploads/2019/04/Digital-Product-Development_Content-1024x639.jpg" alt="" class="product__image">            
                </div>
                <div class="col-8">
                    <span class="tags">Files</span>
                    <h6 >advance booking (offline) - february batch </h6>
                    <h6>&#8377;9999/-</h6>
                </div>
            </div>
        </div>
    <!-- </div> -->
<br><br><br>
      <!-- Footer area -->
      <div class="row">
         <div class="footer">
            <div class="row">
                <div class="col-3" align="center" @click="$router.push('/')">
                  <i class="fa fa-home"></i>
                  <p>Home</p>
               </div>
               <div class="col-3" align="center" @click="$router.push('AllCourse')">
                  <i class="fa fa-book"></i>
                  <p>All Course</p>
               </div>
               <div class="col-3" align="center" @click="$router.push('MyCourse')">
                  <i class="fa fa-folder-open"></i>
                  <p>My Course</p>
               </div>
               <div class="col-3" align="center" @click="$router.push('Login')">
                  <i class="fa fa-user-circle"></i>
                  <p>My Profile</p>
               </div>
            </div>
         </div>
      </div>
   </div>
 </template>
 
 <script>
 require('../assets/css/allcourse.css');
 require('../assets/css/footnav.css');
 export default {
   name: "AllCourseView",
 };
 </script>
 